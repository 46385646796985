<template>
  <div class="report-user-card">
    <section class="column-wrapper-main">
      <div class="left-column-main">
        <DisplayAvatar :avatar="getAvatar" :width="`4rem`" :height="`4rem`" />
      </div>

      <div class="right-column-main">
        <div>
          <h6 class="font-medium text-14px text-muted">
            {{
              $t(
                'components.reportManagement.details.payment.reportedUserScreen.reportedBy'
              )
            }}
          </h6>
          <h2 class="font-medium text-22px text-oDark">
            {{ details.user ? details.user.full_name : '--' }}
          </h2>
        </div>

        <div v-if="details.user_point">
          <div class="points green">
            {{ details.user_point.point }}
          </div>
        </div>
      </div>
    </section>

    <section class="column-wrapper-main">
      <div class="left-column-main" />
      <div class="right-column-main">
        <h5 class="text-title">
          {{
            $t(
              'components.reportManagement.details.payment.reportedUserScreen.reportID'
            )
          }}
        </h5>

        <h5 class="text-value">
          {{ $truncate(details.id, -5) }}
        </h5>
      </div>
    </section>

    <section class="column-wrapper-main">
      <div class="left-column-main" />
      <div class="right-column-main">
        <h5 class="text-title">
          {{
            $t(
              'components.reportManagement.details.payment.reportedUserScreen.reportDate'
            )
          }}
        </h5>

        <h5 class="text-value">
          {{ details.report_date ? $localTime(details.report_date) : '--' }}
        </h5>
      </div>
    </section>

    <section class="column-wrapper-main">
      <div class="left-column-main" />
      <div class="right-column-main">
        <h5 class="text-title">
          {{
            $t(
              'components.reportManagement.details.payment.reportedUserScreen.transactionID'
            )
          }}
        </h5>

        <div class="text-value">
          <router-link
            v-if="details.transaction_id"
            :to="{
              name: 'ViewTrip',
              params: { id: details.transaction_id },
            }"
          >
            <div class="font-medium text-blue-500 text-14px">
              {{ $truncate(details.transaction_id, -5) }}
            </div>
          </router-link>
          <div v-else>
            --
          </div>
        </div>
      </div>
    </section>

    <section class="column-wrapper-main">
      <div class="left-column-main" />
      <div class="right-column-main">
        <h5 class="text-title">
          {{
            $t(
              'components.reportManagement.details.payment.reportedUserScreen.tripID'
            )
          }}
        </h5>

        <div class="text-value">
          <router-link
            v-if="details.trip"
            :to="{
              name: 'ViewTrip',
              params: { id: details.trip.id },
            }"
          >
            <div class="font-medium text-blue-500 text-14px">
              {{ $truncate(details.trip.id, -5) }}
            </div>
          </router-link>
          <div v-else>
            --
          </div>
        </div>
      </div>
    </section>

    <section class="column-wrapper-main">
      <div class="left-column-main" />
      <div class="right-column-main">
        <h5 class="text-title">
          {{
            $t(
              'components.reportManagement.details.payment.reportedUserScreen.tripStatus'
            )
          }}
        </h5>

        <h5 class="text-value">
          <XStatus
            v-if="details.prev_trip"
            :text="`${getTripStatusText(details.prev_trip.status)}`"
            :variant="`${getTripStatusColor(details.prev_trip.status)}`"
            :profile="`trip`"
          />
          <div v-else v-text="`--`" />
        </h5>
      </div>
    </section>

    <section class="column-wrapper-main">
      <div class="left-column-main" />
      <div class="right-column-main">
        <h5 class="text-title">
          {{
            $t(
              'components.reportManagement.details.payment.reportedUserScreen.description'
            )
          }}
        </h5>

        <h5 class="text-value word-wrap">
          {{ details.description }}
        </h5>
      </div>
    </section>
  </div>
</template>

<script>
// eslint-disable-next-line
import { getTripStatusColor, getTripStatusText } from '@/utils/TripStatus'

export default {
  name: 'ReporterUserAltBlock',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    DisplayAvatar: () => import('@/components/ui/DisplayAvatar'),
    XStatus: () => import('@/components/badge/XStatus'),
  },
  computed: {
    getAvatar() {
      return this.details.user ? this.details.user.profile_pic : null
    },
  },
  methods: {
    getTripStatusText,
    getTripStatusColor,
  },
}
</script>

<style lang="scss" scoped>
@import '$report-user-card';
.word-wrap {
  word-wrap: break-word;
}
</style>
